<template>
  <div>
    <v-row
      class="hb-table-row"
      :class="{ 'ma-0': isPromotions || isRentManagement }"
    >
      <v-col :md="isPromotions ? 3 : 4" class="key-label pl-6 pt-4">
        <div>Rounding</div>
      </v-col>
      <v-col :md="isPromotions ? 9 : 8" class="ma-0 pa-0">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'rounding'"
          :hasPermissionToEdit="hasPermission('manage_promotions')"
          name="rounding"
        >
          <template slot="editingMode">
            <v-row class="mx-0 ma-0">
              <div class="hb-text-night-light">
                Define how the rent amount will be rounded.
              </div>
              <v-col class="pa-0 mx-0 mb-n3 mt-3" md="6">
                <hb-radio-group v-model="rounding.round_type">
                  <hb-radio
                    :value="rt.value"
                    v-for="(rt, i) in Object.values(ROUNDING.ROUND_TYPE)" :key="i"
                  >
                    <template v-slot:label>
                      {{rt.text}}
                    </template>
                    <template
                      v-slot:tooltipBody
                      v-if="rt.value == 'nearest'"
                    >
                      The dollar amount will round <br />
                      up or down to the nearest <br />
                      dollar amount that is selected.
                    </template>
                  </hb-radio>
                </hb-radio-group>
              </v-col>
            </v-row>
            <v-row class="ma-0 mt-4">
              <div class="hb-text-night-light">
                Define how the rent amount will round to the nearest dollar.
              </div>
              <v-col class="pa-0 mx-0 mb-n3 mt-3" md="6">
                <hb-radio-group v-model="rounding.dollar_type" v-for="(dt, i) in Object.values(ROUNDING.DOLLAR_TYPE)" :key="i">
                  <hb-radio
                    :label="dt.text"
                    :value="dt.value"
                  ></hb-radio>
                </hb-radio-group>
              </v-col>
            </v-row>
          </template>
          <!-- Read mode -->
          <template slot="readMode" v-if="editMode">
            <v-row class="ma-0">
              <div class="hb-text-night-light">
                Define how the rent amount will be rounded.
              </div>
              <v-col class="px-0 ma-0" md="6">
                {{ getRoudingType }}
                <v-tooltip bottom class="ml-10" v-if="rounding.round_type == 'nearest'">
                  <template v-slot:activator="{ on }">
                    <v-hover v-slot:default="{ hover }">
                      <span v-on="on">
                        <hb-icon small color="#637381" class="ml-1"
                          >mdi-information</hb-icon
                        >
                      </span>
                    </v-hover>
                  </template>
                  <span>
                    <div class="py-1">
                      The dollar amount will round <br />
                      up or down to the nearest <br />
                      dollar amount that is selected.
                    </div>
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="ma-0" v-if="rounding.round_type">
              <div class="hb-text-night-light">
                Define how the rent amount will round to the nearest dollar.
              </div>
              <v-col class="ma-0 pa-0 mt-3" md="6">
                {{ getDollarType }}
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
    </v-row>
    <div></div>
  </div>
</template>

<script type="text/babel">
import HoverableEditField from "./HoverableEditField.vue";
import ROUNDING from "@/constants/rounding.js";
import { EventBus } from "../../EventBus.js";
import { mapGetters } from "vuex";
export default {
  name: "Rounding",
  props: ["selected", "from"],
  data() {
    return {
      activelyEditing: "",
      rounding: {
        round_type: 0,
        dollar_type: "full",
      },
      isPromotions: false,
      isRentManagement: false,
    };
  },
  components: {
    HoverableEditField,
  },
  created() {
    this.ROUNDING = ROUNDING;
    if (this.editMode && this.selected.rounding) {
      this.rounding = Object.assign({}, this.selected.rounding);
    }
    switch (this.from) {
      case "rentManagement":
        this.isRentManagement = true;
        break;
      case "promotions":
        this.isPromotions = true;
        break;
      default:
        this.isPromotions = false;
    }
    EventBus.$on("activeEdit", this.setActiveEditName);
  },
  destroyed() {
    EventBus.$off("activeEdit", this.setActiveEditName);
  },
  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
      activeDrawerComponent: 'navigationStore/activeDrawerComponent'
    }),
    editMode() {
      if (this.selected && !this.isRentManagement && (this.selected.id || this.activeDrawerComponent.rent_raise_drawer)) {
        return true;
      }
      return false;
    },
    getRoudingType() {
      return this.ROUNDING.ROUND_TYPE.find(
        (rt) => rt.value == this.rounding.round_type
      ).text;
    },
    getDollarType() {
      return this.ROUNDING.DOLLAR_TYPE.find(
        (rt) => rt.value == this.rounding.dollar_type
      ).text;
    },
    getRoundingData() {
      return this.rounding.round_type ? this.rounding : null;
    },
  },
  methods: {
    setActiveEditName(name) {
      if (name == "") {
        this.rounding =
          this.editMode && this.selected.rounding
            ? Object.assign({}, this.selected.rounding)
            : { round_type: 0, dollar_type: "full" };
      }
      this.activelyEditing = name;
    },
    setRoundingObject() {},
  },
  watch: {
    "rounding.round_type"(val) {
      EventBus.$emit("saveRoundingData", this.getRoundingData);
    },
    "rounding.dollar_type"(val) {
      EventBus.$emit("saveRoundingData", this.getRoundingData);
    },
    selected(val) {
      this.rounding = val.rounding && (val.id || this.activeDrawerComponent.rent_raise_drawer)
        ? Object.assign({}, this.selected.rounding)
        : { round_type: 0, dollar_type: "full" };
    },
  },
};
</script>

<style scoped>
.key-label {
  background: #f4f6f8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}
</style>
