export default Object.freeze({
	ROUND_TYPE: [
		{ text: "No Rounding", value: 0 },
		{ text: "Round to nearest", value: "nearest" },
		{ text: "Round up to", value: "up" },
		{ text: "Round down to", value: "down" }
	],
	DOLLAR_TYPE: [
		{ text: "Full Dollar", value: "full" },
		{ text: "Half Dollar", value: "half" },
	]
});